<template>
  <section>
    <div class="row header__row">
      <div class="col">
        № заявки
      </div>
      <div class="col">
        Номинация
      </div>
      <div class="col">
        Проект
      </div>
      <div class="col">
        Описание проекта
      </div>
      <div class="col">
        Статус заявки
      </div>
    </div>

    <project-table-row v-for="(project, index) in getProjectAppeals" :project="project" :key="`projectRow${index}`"/>
  </section>
</template>

<script>
import ProjectTableRow from './ProjectTableRow'

export default {
  name: 'ProjectsCardsTable',
  data () {
    return {
      // statuses: 1 - на рассмотрении, 2 - рассмотрена, 3 - отклонена
      projects: []
    }
  },

  async created () {
    await this.$store.dispatch('getPersonalData')
    await this.$store.dispatch('getAllProjectAppeals')
    await this.$store.dispatch('setNomination')
  },

  computed: {
    getProjectAppeals () {
      return this.$store.getters.getProjectAppeals
    }
  },

  components: {
    ProjectTableRow
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;

    ::v-deep .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.375rem;
      width: calc(100% + 2.75rem);
      margin-left: -1.375rem;
      border: 1px solid transparent;
      border-bottom: .06rem solid $neutral-secondary;
      transition: .35s;
      box-sizing: border-box;
      border-radius: .5rem;

      &:hover {
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.35);
        border: 1px solid #EBF3FF;
      }

      &:last-child {
        border-bottom: none;
        /*padding-bottom: 0;*/
      }

      > .col {
        font-size: .875rem;
        line-height: 1.375rem;
        color: #221719;
        opacity: 0.8;

        &:nth-child(1) {
          width: 4.6875rem;
        }
        &:nth-child(2) {
          width: 13.75rem;
        }
        &:nth-child(3) {
          width: 19.375rem;
        }
        &:nth-child(4) {
          width: 26.56rem;
        }
        &:nth-child(5) {
          width: 12.187rem;
        }
      }
    }

    .header__row {
      padding: .75rem 1.375rem;

      > .col {
        font-size: .75rem;
        line-height: 1.125rem;
        color: $neutral-regular;
      }
    }

  }
</style>
