<template>
  <section>
    <input type="text" placeholder="Поиск" class="input blue" id="search__input" v-model="search.key">

    <custom-select :title="getNomination.title"
                   :key="`nominationSelect`"
                   class="select"
                   :list="getNomination.list"
                   @InputsContent="setNomination"
                   :placeholder="getNomination.title"/>

    <custom-select :title="statuses.title"
                   :key="`statusesSelect`"
                   class="select"
                   :list="statuses.list"
                   @InputsContent="setStatus"
                   :placeholder="statuses.title"/>

    <custom-select :title="faceType.title"
                   :key="`faceTypeSelect`"
                   class="select"
                   :list="faceType.list"
                   @InputsContent="setFaceType"
                   :placeholder="faceType.title"/>

    <custom-select :title="yearStatus.title"
                   :key="`yearStatusSelect`"
                   class="select"
                   :list="yearStatus.list"
                   @InputsContent="setYearType"
                   :placeholder="yearStatus.title"/>
  </section>
</template>

<script>
import CustomSelect from '../forms/CustomSelect'

export default {
  name: 'FiltersList',
  data () {
    return {
      search: {
        key: null,
        nomination: null,
        status: null,
        faceType: null,
        yearStatusBefore: null,
        yearStatusAfter: null
      },
      nominations: {
        name: 'nomination',
        title: 'Все номинации',
        list: [
          {
            id: 1,
            code: 'center',
            title: 'Центральный'
          }
        ]
      },
      statuses: {
        name: 'statuses',
        title: 'Все статусы',
        list: [
          {
            id: 0,
            code: null,
            title: 'Все статусы'
          },
          {
            id: 1,
            code: 'UNDER_CONSIDERATION',
            title: 'На рассмотрении'
          },
          {
            id: 2,
            code: 'REVIEWED',
            title: 'Рассмотрена'
          },
          {
            id: 3,
            code: 'REJECTED',
            title: 'Отклонена'
          },
          {
            id: 4,
            code: 'DRAFT',
            title: 'Черновик'
          }
        ]
      },
      faceType: {
        name: 'faceType',
        title: 'Все типы',
        list: [
          {
            id: 0,
            code: null,
            title: 'Все типы'
          },
          {
            code: 'PHYSICAL',
            id: 1,
            order: null,
            title: 'физ. лицо'
          },
          {
            code: 'LEGAL',
            id: 2,
            order: null,
            title: 'юр. лицо'
          }
        ]
      },
      yearStatus: {
        name: 'yearStatus',
        title: 'Все проекты',
        list: [
          {
            id: 0,
            code: null,
            date: null,
            title: 'Все проекты'
          },
          {
            id: 1,
            code: 'BEFORE',
            date: '2020-06-12',
            title: 'до 12.06.2020'
          },
          {
            id: 2,
            code: 'AFTER',
            date: '2020-06-12',
            title: 'после 12.06.2020'
          }
        ]
      }
    }
  },

  watch: {
    search: {
      deep: true,
      handler () {
        this.$store.commit('setProjectAppealSearch', this.search)
        this.$store.dispatch('getAllProjectAppeals')
      }
    }
  },

  methods: {
    setStatus (value) {
      this.search.status = value.code
    },

    setNomination (value) {
      this.search.nomination = value.id
    },

    setFaceType (value) {
      this.search.faceType = value.code
    },

    setYearType (value) {
      if (value.code) {
        if (value.code === 'BEFORE') {
          this.search.yearStatusBefore = value.date
          this.search.yearStatusAfter = null
        } else {
          this.search.yearStatusBefore = null
          this.search.yearStatusAfter = value.date
        }
      } else {
        this.search.yearStatusBefore = null
        this.search.yearStatusAfter = null
      }
    }
  },

  computed: {
    getNomination () {
      return {
        name: 'nomination',
        title: 'Все номинации',
        list: [
          {
            id: null,
            title: 'Все номинации'
          },
          ...this.$store.getters.getNomination
        ]
      }
    }
  },

  components: {
    CustomSelect
  }
}
</script>

<style scoped lang="scss">
  section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    > #search__input {
      width: 26.687rem;
      position: relative;

      &:after {
        content: url("../../assets/icons/search.svg");
        position: absolute;
        right: 0;
        top: 50%;
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    //> #nominationsSelect {
    //  margin-right: 2.187rem;
    //  margin-left: auto;
    //}

    > .select {
      width: 15rem;
      margin-left: 1rem;
    }
  }
</style>
