<template>
  <router-link :to="`/project-map/${project.id}`" class="row">
    <div class="col">
      {{ project.id }}
    </div>
    <div class="col">
      {{ project.nomination_model ? project.nomination_model.title : ''}}
    </div>
    <div class="col">
      {{ project.personal ? project.personal.first_name : ''}}
    </div>
    <div class="col">
      {{ project.description ? project.description.description : ''}}
    </div>
    <div class="col">
      <div class="status__block" :style="getStatusColor">
        {{ getStatusText }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ProjectTableRow',

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      statusesColors: {
        UNDER_CONSIDERATION: {
          color: '#246CB7',
          title: 'На рассмотрении'
        },
        REVIEWED: {
          color: '#8ABD1F',
          title: 'Рассмотрена'
        },
        REJECTED: {
          color: '#EB494E',
          title: 'Отклонена'
        },
        DRAFT: {
          color: '#b6b32b',
          title: 'Черновик'
        },
        FINAL: {
          color: '#982bb6',
          title: 'Финал'
        }
      }
    }
  },

  computed: {
    getStatusColor () {
      return {
        backgroundColor: this.statusesColors[this.project.status.code].color
      }
    },

    getStatusText () {
      return this.statusesColors[this.project.status.code].title
    },

    getNomination () {
      const nominations = this.$store.getters.getNomination.filter((nomination) => nomination.id === this.project.personal.nomination_id)
      if (nominations.length) {
        return nominations[0].title
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .status__block {
    padding: .125rem .625rem;
    border-radius: .187rem;
    font-weight: 600;
    font-size: .812rem;
    line-height: 1rem;
    color: white;
    display: inline-block;
  }

</style>
