<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>

    <div class="white-container">
      <h1>
        Картотека проектов
        <button @click="downloadExcel" class="btn-blue" v-if="getProjectUserRole === 'ORGANIZER'">
          Выгрузка Excel
        </button>
        <button @click="startFinal" class="btn-blue" v-if="getProjectUserRole === 'ORGANIZER'">
          Старт финала
        </button>
        <button @click="downloadDuplicates" class="btn-blue" v-if="getProjectUserRole === 'ORGANIZER'">
          Дубликаты
        </button>
        <button @click="reviewAppeals" class="btn-blue" v-if="getProjectUserRole === 'ORGANIZER'">
          Одобрить заявки
        </button>
      </h1>

      <tricolor-line/>

      <filters-list/>

      <projects-cards-table/>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import FiltersList from '@/components/projectsList/FiltersList'
import ProjectsCardsTable from '@/components/projectsList/ProjectsCardsTable'

export default {
  name: 'ProjectsList',

  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Личный кабинет'
        }
      ]
    }
  },

  methods: {
    async reviewAppeals () {
      await fetch(`${this.$store.getters.getUrlApi}api/user/project_appeals_review_all`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          this.$store.dispatch('getAllProjectAppeals')
          this.$store.commit('setMessage', {
            type: 'success',
            text: 'Успешное одобрение заявок'
          })
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('setMessage', {
            type: 'error',
            text: 'Ошибка одобрения'
          })
        })
    },

    async downloadExcel () {
      await fetch(`${this.$store.getters.getUrlApi}api/user/project_appeals_excel`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => res.blob())
        .then(blob => {
          const file = window.URL.createObjectURL(blob)
          window.location.assign(file)
        })
        .catch(error => console.log('error', error))
    },

    async downloadDuplicates () {
      await fetch(`${this.$store.getters.getUrlApi}api/user/project_appeals_duplicates`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => res.blob())
        .then(blob => {
          const file = window.URL.createObjectURL(blob)
          window.location.assign(file)
        })
        .catch(error => console.log('error', error))
    },

    async startFinal () {
      await fetch(`${this.$store.getters.getUrlApi}api/user/project_appeals_start`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          this.$store.dispatch('getAllProjectAppeals')
          this.$store.commit('setMessage', {
            type: 'success',
            text: 'Успешный старт финала'
          })
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('setMessage', {
            type: 'error',
            text: 'Ошибка старта финала'
          })
        })
    }
  },

  computed: {
    getProjectUserRole () {
      return this.$store.getters.getProjectUserRole
    }
  },

  components: {
    ProjectsCardsTable,
    FiltersList,
    TricolorLine,
    BreadCrumb
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .white-container {

    > h1 {
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.75rem;
      color: $mainFontColor;
      margin-bottom: 2rem;

      > a {
        font-size: 1rem;
        color: #246CB7;
      }
    }
  }
</style>
